// import styling
// import 'antd/dist/reset.css'
import '@assets/styles/main.scss'

import AuthLayout from "@app/components/layouts/AuthLayout";
import NotFound from "@views/not-found/NotFound";
import { useMemo } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { routes } from "./app/router";
import PrivateRoute from "./app/router/PrivateRoute";
import PublicRoute from "./app/router/PublicRoute";
function App() {
  const location = useLocation();
  const getLayout = useMemo((): JSX.Element => {
    return <AuthLayout />;
  }, [location]);
  return (
    <Routes>
      <Route path="/" element={getLayout}>
        {routes.map((route) => (
          <Route
            key={route.key}
            path={route.path}
            element={
              route.public ? (
                <PublicRoute>
                  <route.component />
                </PublicRoute>
              ) : (
                <PrivateRoute>
                  <route.component />
                </PrivateRoute>
              )
            }
          />
        ))}
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
