import {
    ILoginPayload,UpdateDefinitionPayload
  } from '@app/types/auth'
import { showNotification } from '@app/utils/helperFunction'
  import {
    BaseQueryFn,
    createApi,
    FetchArgs,
    fetchBaseQuery,
    FetchBaseQueryError
  } from '@reduxjs/toolkit/query/react'
  import { TOKEN_KEY } from "@app/config/const";
  
  const fetchBaseQueryBasic = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_BASE_URL,
    prepareHeaders: (headers) => {
      const accessToken = sessionStorage.getItem(TOKEN_KEY);
      headers.set("authorization", `Bearer ${accessToken}`);
      headers.set("Accept", "application/json");
      return headers;
    },
  });
  
  const baseQueryAuth: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
  > = async (args, api, extraOptions) => {
    const result = await fetchBaseQueryBasic(args, api, extraOptions)
  
    if (result.error) {
      switch (result.error.status) {
        case 422:
          showNotification('error', (result?.error?.data as any)?.message)
          break
        default:
          break
      }
    }
    return result
  }
  const baseQueryUpdate: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
  > = async (args, api, extraOptions) => {
    const result = await fetchBaseQueryBasic(args, api, extraOptions);

    if (result.error) {
      switch (result.error.status) {
        case 422:
          showNotification("error", (result?.error?.data as any)?.message);
          break;
        default:
          break;
      }
    }
    return result;
  };
  
  export const authApi = createApi({
    baseQuery: baseQueryAuth,
    reducerPath: 'authApi',
    endpoints: (build) => ({
      login: build.mutation({
        query: (data: ILoginPayload) => ({
          url: '/api/auth/login',
          method: 'POST',
          body: data
        })
      }),
    })
  })
  export const definitionApi = createApi({
    baseQuery: baseQueryUpdate,
    reducerPath: "definitionApi",
    endpoints: (build) => ({
      update: build.mutation({
        query: (data: UpdateDefinitionPayload) => ({
          url: "/api/auth/update/definition_status",
          method: "POST",
          body: data,
        }),
      }),
    }),
  });
  
  export const { useLoginMutation} = authApi;
   export const { useUpdateMutation } = definitionApi;
  