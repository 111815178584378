import { NotificationType } from "@app/types/common";
import { notification } from "antd";

export const test = () => {};
/**
 * function show notification depend type
 * type: "success", "info", "warning", "error"
 * @param type
 * @param message
 */
export const showNotification = (type: NotificationType, message: string) => {
  notification.destroy();
  notification[type]({ message });
};

export const handleCheckPreEnterInput = (event: any) => {
    const charCode = event.which ? event.which : event.keyCode;
  if ((charCode < 48 || charCode > 57) && charCode !== 46) {
    event.preventDefault();
      }
};
