import { combineReducers } from "@reduxjs/toolkit";
import auth from "./slices/auth.slice";
import { authApi, definitionApi } from "./api/auth.api";

const rootReducer = combineReducers({
  auth: auth,
  [authApi.reducerPath]: authApi.reducer,
  [definitionApi.reducerPath]:definitionApi.reducer
});

export default rootReducer;
