import { createSlice } from "@reduxjs/toolkit";

import { TOKEN_KEY } from "@app/config/const";
import { ResponseAuth } from "@app/types/auth";
import { IResponseData } from "@app/types/common";
import { authApi } from "@store/api/auth.api";

const name = "authSlice";

const initialState = {};

const authSlice = createSlice({
  name: name,
  initialState: initialState,
  reducers: {
    logout: (state) => {
      state = initialState;
      sessionStorage.removeItem("token");
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      authApi.endpoints.login.matchFulfilled,
      (state, { payload }: { payload: IResponseData<ResponseAuth> }) => {
        if (payload.status) {
          sessionStorage.setItem(TOKEN_KEY, payload?.data.user.token);

          return {
            ...state,
            token: payload?.data.user.token,
            customers: payload?.data.user,
          };
        }
      }
    );
  },
});

export const { logout } = authSlice.actions;

export default authSlice.reducer;
