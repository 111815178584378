import React from "react";
import { Outlet } from "react-router-dom";
import "./style.scss"
const AuthLayout = () => {
  return (
    <div className="app-auth-container">
      <main>
        <Outlet />
      </main>
    </div>
  );
};

export default React.memo(AuthLayout);
