import { FunctionComponent, LazyExoticComponent, lazy } from "react";
import { appPaths, authPaths } from "./path";

const LoginPage = lazy(() => import("@views/auth-views/login/Login"));
const Mesurement = lazy(() => import("@views/app-views/mesurement/Mesurement"));
const DefinitionPage = lazy(
  () => import("@views/app-views/definition/Definition")
);
const PreparationPage = lazy(
  () => import("@views/app-views/preparation/preparation")
);
interface Route {
  key: string;
  path: string;
  component: LazyExoticComponent<FunctionComponent | React.FC>;
  public: boolean;
}
export const routes: Route[] = [
  {
    key: "login",
    path: authPaths.login,
    component: LoginPage,
    public: true,
  },
  {
    key: "home",
    path: appPaths.home,
    component: Mesurement,
    public: false,
  },
  {
    key: "definition",
    path: appPaths.definition,
    component: DefinitionPage,
    public: false,
  },
  {
    key: "checkbox-form",
    path: appPaths.preparation,
    component: PreparationPage,
    public: false,
  },
];
