import store from '@store/index'
import { ConfigProvider } from 'antd'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import App from './App'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <Provider store={store}>
    {/* <PersistGate
      loading={null}
      persistor={persistor}
    > */}
      <Router>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: '#9BB73F',
              fontFamily: 'Meirio, sans-serif'
            }
          }}
        >
            <App />
        </ConfigProvider>
      </Router>
    {/* </PersistGate> */}
  </Provider>
);
